<template>
    <div v-if="loading" :class="`main-loader ${innerLoading ? 'inner-loader' : ''}`"><img :src="dynamicAsset" alt="Loading" /></div>
</template>

<script>
import { computed } from 'vue';
export default {
    name: 'InnerLoader',
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        innerLoading: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        const ASSETS_CLIENT_NAME = process.env.VUE_APP_ASSETS_CLIENT_NAME;

        const dynamicAsset = computed(() => {
            return require(`@/assets/media/${ASSETS_CLIENT_NAME}/${ASSETS_CLIENT_NAME}_loader.svg`);
        });

        return {
            ASSETS_CLIENT_NAME,
            dynamicAsset
        };
    }
};
</script>

<style scoped lang="scss">
.main-loader {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    img {
        display: block;
        max-width: 8rem;
        margin: 0 auto;
        height: auto;
        animation-name: loadingAnimation;
        animation-duration: 1.5s;
        animation-fill-mode: both;
        animation-iteration-count: infinite;
    }

    &.inner-loader {
        position: absolute;

        img {
            max-width: 6rem;
        }
    }
}

@keyframes loadingAnimation {
    0% {
        transform: scale(0.3) rotate(720deg);
        opacity: 0;
    }

    50% {
        transform: scale(1) rotate(0);
        opacity: 1;
    }

    100% {
        transform: scale(0.3) rotate(0);
        opacity: 0;
    }
}
</style>
