
import { defineComponent, ref, inject, computed } from 'vue';
import { LoginFormData } from '../index';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { Notify } from '@/core/services';
import { useI18n } from 'vue-i18n';
import InnerLoader from '../../../components/InnerLoader.vue';

export default defineComponent({
    name: 'logn-form',

    components: {
        InnerLoader
    },

    setup() {
        const loginForm = ref<null | HTMLFormElement>(null);
        const store = useStore();
        const i18n = useI18n();
        const router = useRouter();
        const appInstance: any = inject('instance');
        const loading = ref(false);
        const session_div = ref(false);
        const session_duration = ref();
        const session_timestamp = ref();
        const login_date = ref();
        const sso_url = `${process.env.VUE_APP_GEP_AUTH}/token/request?client_id=${process.env.VUE_APP_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_CLIENT_CALLBACK}&response_type=code`;
        const loginData = ref<LoginFormData>({
            email: '',
            password: ''
        });
        const ASSETS_CLIENT_NAME = process.env.VUE_APP_ASSETS_CLIENT_NAME;

        if (localStorage.getItem('session-timestamp')) {
            session_div.value = true;
            session_timestamp.value = localStorage.getItem('session-timestamp');
            const storedData = JSON.parse(session_timestamp.value);
            session_duration.value = storedData.session_duration;
            login_date.value = storedData.login_date;
        }
        const onSubmitLogin = async () => {
            if (!loginForm.value) {
                return;
            }
            loginForm.value.validate(async (valid: boolean) => {
                if (valid) {
                    const formData = {
                        email: loginData.value.email,
                        password: loginData.value.password
                    };

                    try {
                        loading.value = true;
                        await store.dispatch('loginUser', formData);
                        const response = await store.dispatch('abilities', appInstance);
                        loading.value = false;
                        if (response.data?.roles?.admin) {
                            localStorage.removeItem('A_ID');
                            // await i18nProvider.SetLocaleMessage(response?.data?.info?.locale);
                            Notify.success(i18n.t('message.SUCCESSFULLY_LOGGED_IN'));
                            router.push({ name: 'dashboard' });
                        } else {
                            Notify.info(i18n.t('message.UNAUTHORIZED_ACCESS'));
                            store.dispatch('logout');
                        }
                    } catch (e) {
                        loading.value = false;
                        appInstance.config.globalProperties.$notify.error({
                            title: 'Error',
                            message: e.response.data.message
                        });
                        localStorage.setItem('A_ID', e.response.headers.a_id);
                    }
                } else {
                    return false;
                }
            });
        };

        const dynamicAsset = computed(() => {
            return require(`@/assets/media/${ASSETS_CLIENT_NAME}/${ASSETS_CLIENT_NAME}_logo.png`);
        });

        return {
            loginForm,
            loginData,
            loading,
            onSubmitLogin,
            sso_url,
            session_div,
            session_duration,
            login_date,
            ASSETS_CLIENT_NAME,
            dynamicAsset
        };
    }
});
